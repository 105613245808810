<template>
  <div>
    <p class="situation-kpi-title m-b-5">年度目标完成情况</p>
    <Row class="p-b-5  m-l-5">
      <i-col span="24">
        统计年度：
        <RadioGroup v-model="chooseYear" size="small" type="button" button-style="solid">
          <Radio label="2020">2020</Radio>
          <Radio label="2021">2021</Radio>
          <Radio label="2022">2022</Radio>
          <Radio label="2023">2023</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="table-title p-l-10 p-r-10" :gutter="8">
      <i-col span="8">周期</i-col>
      <i-col span="8" class="text-right">合同执行额(元)</i-col>
      <i-col span="8" class="text-right">结算金额(元)</i-col>
      <!-- <i-col span="6" class="text-right">结算比例</i-col> -->
    </Row>
    <Row v-for="(item,index) in listExecuteAndBillGbQuarterData" :key="index" :gutter="8" class="p-t-5 p-l-10 p-r-10">
      <i-col span="8">{{item.name}}</i-col>
      <i-col span="8" class="text-right">{{formatNumber(item.executeAmount)}}</i-col>
      <i-col span="8" class="text-right">{{formatNumber(item.actualAmount)}}</i-col>
      <!-- <i-col span="6" class="text-right">{{item.rate}} %</i-col> -->
    </Row>
    <p class="remark p-t-5">* 已完成合同执行额未包含调整项金额以及罚款项金额，实际已结算合同执行额以结算台为准</p>
  </div>
</template>

<script>
import { toNumber } from '@/utils/wnumb_own'
import { listExecuteAndBillGbQuarter } from '@/api/dw/kpi'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      chooseYear: '2022',
      listExecuteAndBillGbQuarterData: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const queryModel3 = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        year: this.chooseYear
      }

      listExecuteAndBillGbQuarter(queryModel3).then(res => {
        this.listExecuteAndBillGbQuarterData = res
      })
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    chooseYear () {
      this.initPageData()
    }
  }
}
</script>

<style>

</style>
